import Presentation from "./presentation";

const ShareMainContent = ( { handleClose, shareLink, title, onClickShareItem } ) => {
	return (
		<Presentation
			handleClose      = { handleClose }
			onClickShareItem = { onClickShareItem }
			shareLink        = { shareLink }
			title            = { title }
		/>
	);
};

export default ShareMainContent;