import React from "react";

import Presentation from "./presentation";

const component = ( {
	anchorEl,
	getSrc,
	handleClose,
	open,
	placement,
	title,
	useModal,
	onClickShareItem
} ) => {

	return (
		<Presentation
			anchorEl         = { anchorEl }
			getSrc           = { getSrc }
			handleClose      = { handleClose }
			onClickShareItem = { onClickShareItem }
			open             = { open }
			placement        = { placement }
			title            = { title }
			useModal         = { useModal }

		/>
	);
};

export default component;
