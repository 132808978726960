import { Drawer } from "@mantine/core";

import useStyles from "_styles/molecules/sharePopper";

import ShareMainContent from "./shareMainContent";
import ShareModal       from "./shareModal";

const SharePopper = ( {
	anchorEl,
	getSrc,
	handleClose,
	open,
	placement,
	title,
	useModal = true,
	onClickShareItem
} ) => {

	const shareLink   = getSrc ();
	const id          = open ? "simple-popper" : undefined;
	const { classes } = useStyles ( undefined, { name: "styles" } );

	if ( useModal ) {
		return (
			<ShareModal
				handleClose      = { handleClose }
				onClickShareItem = { onClickShareItem }
				open             = { open }
				shareLink        = { shareLink }
				title            = { title }
			/>
		);
	}

	return (
		<div>

			<Drawer
				disablePortal
				anchorEl            = { anchorEl }
				className           = { classes.popperRoot }
				closeOnClickOutside = { true }
				id                  = { id }
				modifiers           = { [
					{
						name    : "flip",
						enabled : false
					}
				] }
				open                = { open }
				placement           = { placement }
				sx                  = { { background: "white" } }
			>

				<ShareMainContent
					handleClose      = { handleClose }
					onClickShareItem = { onClickShareItem }
					shareLink        = { shareLink }
					title            = { title }
				/>

			</Drawer>
		</div>
	);
};

export default SharePopper;