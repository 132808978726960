import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => ( {
	drawer: {
		background: "inherit"
	},

	body: {
		// height: "100%"
	},

	content: {
		overflowY    : "initial",
		borderRadius : props?.noBorderRadius ? "unset" : "1.2rem 1.2rem 0 0",
		background   : "inherit",
		overflowX    : "hidden",
		height       : "unset"
	},

	noOverlay: {
		backgroundColor : "unset",
		zIndex          : "unset"
	}
} ) );
