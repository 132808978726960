import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( theme => ( {
	popperRoot: {
		boxShadow       : "0 0 20px 0 rgba(0, 0, 0, 0.12)",
		zIndex          : "99",
		borderRadius    : "13px",
		margin          : "1rem 0 !important",
		backgroundColor : theme.other.colors.dialog.primary
	},
	socialMediaWrapper: {
		display         : "flex",
		gap             : "1rem",
		borderRadius    : "0 0 10px 10px",
		padding         : "1.5rem",
		backgroundColor : theme.other.colors.dialog.secondary,

		[rooterMediaQuery.mobile]: {
			borderRadius        : "unset",
			justifyContent      : "space-evenly",
			display             : "grid",
			gridTemplateColumns : "repeat(5, 1fr)"
		}
	},
	header: {
		display         : "flex",
		alignItems      : "center",
		justifyContent  : "space-between",
		borderBottom    : theme.other.darkMode ? "none" : `1px solid ${ theme.other.colors.border.primary }`,
		padding         : "1rem 2rem",
		backgroundColor : theme.other.colors.dialog.primary
	},
	headerText: {
		fontSize   : "1.8rem",
		margin     : "0.4rem 0",
		fontWeight : "500"
	},
	iconNames: {
		fontSize: "1.5rem",

		[rooterMediaQuery.mobile]: {
			fontSize: "1.2rem"
		}
	},
	closeIcon: {
		height : "3rem",
		width  : "3rem",
		cursor : "pointer"
	},
	closeIconBtn: {
		border     : "none",
		background : "none"
	},
	socialMediaIcons: {
		margin : "0rem 1rem",
		width  : "6rem",

		[rooterMediaQuery.mobile]: {
			width: "4rem"
		}
	},
	socialMediaButtons: {
		display       : "flex",
		alignItems    : "stretch",
		flexDirection : "column",
		border        : "none",
		// width         : "35%",
		cursor        : "pointer",
		background    : "none"
	},
	copyLinkIcon: {
		display        : "flex",
		alignItems     : "stretch",
		justifyContent : "space-between",
		padding        : "unset"
	}
} ) );