import Script        from "next/script";
import { useEffect } from "react";
import ReactGA       from "react-ga4";
import TagManager    from "react-gtm-module";

import { getUserData }                  from "_common/utils/user";
import { getParamValueFromQueryString } from "_common/webview";
import {
	APP_VERSION,
	DEVICE_TYPE,
	GA_ID,
	GTM_ID,
	configs
} from "_config/index";

export const initGA = () => {

	ReactGA.initialize ( [
		{
			trackingId  : GA_ID,
			gaOptions   : { source: "desktop_web" }, // not sure if this works
			gtagOptions : {
				dimension1: "desktop_web"
			}
		}
	] );
	ReactGA.send ( "pageview" );
};

export const initGTM = () => {
	TagManager.initialize ( { gtmId: GTM_ID } );
};

export const initClevertap = async () => {
	import ( "clevertap-web-sdk" ).then ( clevertapModule => {
		const clevertap = clevertapModule.default;

		clevertap.init ( configs?.CT_ID );
		clevertap.privacy.push ( { optOut: false } );
		clevertap.privacy.push ( { useIP: false } );
		// clevertap.setLogLevel ( 3 );
		clevertap.spa = true;
	} );
};

export const storeEvent = ( category, action, label, value ) => {
	if ( !category || !action )
		return;

	ReactGA.set ( {
		category,
		action,
		label,
		value
	} );
};

export const storeCTEvent = ( eventName, options ) => {

	let userId;

	// check if we are in webview
	const webviewCheck = getParamValueFromQueryString ( "platform" );

	if ( webviewCheck === "android" || webviewCheck === "ios" )
		userId = getParamValueFromQueryString ( "userId" );
	else {
		const userData = getUserData ();

		userId = userData?.userId;
	}

	import ( "clevertap-web-sdk" ).then ( clevertapModule => {
		const clevertap = clevertapModule.default;

		clevertap.onUserLogin.push ( {
			"Site": {
				Identity : userId,
				userId   : userId
			}
		} );

		clevertap.event.push ( eventName, {
			...options,
			user_id     : userId,
			timestamp   : new Date ().getTime (),
			app_version : getParamValueFromQueryString ( "appVersion" ) ?? APP_VERSION,
			OS          : webviewCheck ?? DEVICE_TYPE
		} );

	} );
};

export const FacebookPixel = () => (
	<>
		<Script id = "facebook-analytics"
			strategy  = "lazyOnload"
		>
			{`
				!function(f,b,e,v,n,t,s){if(f.fbq)return;
        	    n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        	    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        	    n.queue=[];t=b.createElement(e);t.async=!0;
        	    t.src=v;s=b.getElementsByTagName(e)[0];
        	    s.parentNode.insertBefore(t,s)}(window,document,'script',
        	    'https://connect.facebook.net/en_US/fbevents.js');
        	    fbq('init', '277327046323371'); 
        	    fbq('track', 'PageView');
			`}
		</Script>

		<noscript
			dangerouslySetInnerHTML = { {
				__html: `
				 <img
    		          height="1"
    		          width="1"
    		          src="https://www.facebook.com/tr?id=277327046323371&ev=PageView&noscript=1"
			    />`
			} }
		/>
	</>
);

export const GoogleTag = () => (
	<Script id = "google-analytics"
		strategy  = "lazyOnload"
	>
		{`
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TCTVCZL');
		`}
	</Script>
);

export const GoogleTag1 = () => (
	// <!-- Global site tag (gtag.js) - Google Ads: 820567574 -->
	<>
		<Script
			src      = "https://www.googletagmanager.com/gtag/js?id=AW-820567574"
			strategy = "lazyOnload"
		/>

		<Script id = "google-analytics-1"
			strategy  = "lazyOnload"
		>
			{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'AW-820567574');
				gtag('event', 'conversion', {
					'send_to': 'AW-820567574/JhmbCLvEh7ADEJa8o4cD',
					'value': 1.0,
					'currency': 'INR'
				});

			`}
		</Script>
	</>
);

export const GoogleTag2 = () => (
	<>
		<Script
			src      = "https://www.googletagmanager.com/gtag/js?id=AW-800392406"
			strategy = "lazyOnload"
		/>

		<Script id = "google-analytics-2"
			strategy  = "lazyOnload"
		>
			{`
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', 'AW-800392406');
				gtag('event', 'conversion', {'send_to': 'AW-800392406/5PA0COW4p9oBENaJ1P0C'});
			`}
		</Script>
	</>
);

export const Hotjar = () => (
	<Script id = "hotjar-analytics"
		strategy  = "lazyOnload"
	>
		{`
			(function(h,o,t,j,a,r){
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
				h._hjSettings={hjid:1873457,hjsv:6};
				a=o.getElementsByTagName('head')[0];
				r=o.createElement('script');r.async=1;
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
				a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
			`}
	</Script>
);

export const initSingular = () => {
	const singularConfig = JSON.parse ( process.env.singularConfig );

	// Dynamic import
	import ( "singular-sdk" ).then ( ( { singularSdk, SingularConfig } ) => {
		const config = new SingularConfig ( singularConfig.apiKey,
			singularConfig.secretKey, singularConfig.productId );

		singularSdk.init ( config );
	} );
};

export const openAppSingular = ( url, passThroughValue ) => {
	let psnvalue  = "organic";
	let pcidvalue = "-1";
	let pcnvalue  = "organic";
	let query     = window.location.search.substring ( 1 );
	let vars      = query.split ( "&" );

	for ( let i = 0; i < vars.length; i ++ ) {
		let pair = vars[i].split ( "=" );

		if ( decodeURIComponent ( pair[0] ) === "_wpsrc" || decodeURIComponent ( pair[0] ) === "wpsrc" || decodeURIComponent ( pair[0] ) === "utm_source" ) {
			psnvalue = decodeURIComponent ( pair[1] );
		}
		if ( decodeURIComponent ( pair[0] ) === "_wpcid" || decodeURIComponent ( pair[0] ) === "wpcid" ) {
			pcidvalue = decodeURIComponent ( pair[1] );
		}
		if ( decodeURIComponent ( pair[0] ) === "_wpcn" || decodeURIComponent ( pair[0] ) === "wpcn" ) {
			pcnvalue = decodeURIComponent ( pair[1] );
		}
	}
	let fullUrl = url + "?psn=" + psnvalue + "&pcid=" + pcidvalue + "&pcn=" + pcnvalue;

	// Dynamic import
	import ( "singular-sdk" ).then ( ( { singularSdk } ) => {
		singularSdk.openApp ( fullUrl, null, passThroughValue, null );
	} );
};

const Analytics = () => {

	const init = () => {
		initGA ();
		initGTM ();
		initSingular (); // only for mobile
		initClevertap ();
	};

	useEffect ( () => {
		init ();
	}, [] );

	return (
		<FacebookPixel />
	);
};

export default Analytics;
