import { Drawer } from "@mantine/core";

import useStyles from "./styles";

const MobileBottomDrawer = ( {
	children, isOpen, onClose, zIndex = 200,
	hidden = false, position = "bottom",
	size = "fit-content", noBorderRadius = false,
	noOverlay = false, closeOnClickOutside = true, styles
} ) => {

	const { classes } = useStyles ( { noBorderRadius }, { label: "MobileBottomDrawer" } );

	return (
		<Drawer
			keepMounted
			classNames          = { {
				drawer  : classes.drawer,
				body    : classes.body,
				content : classes.content,
				overlay : noOverlay ? classes.noOverlay : ""
			} }
			closeOnClickOutside = { closeOnClickOutside }
			hidden              = { hidden }
			onClose             = { onClose }
			opened              = { isOpen }
			overlayProps        = { noOverlay ? null : { backgroundOpacity: 0.5 } }
			position            = { position }
			size                = { size }
			styles              = { styles }
			withCloseButton     = { false }
			withOverlay         = { !noOverlay }
			zIndex              = { zIndex }
		>
			{children}
		</Drawer>
	);
};

export default MobileBottomDrawer;
