import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	dialogPaper: {
		borderRadius    : "1rem",
		backgroundColor : theme.other.colors.dialog.secondary
	},
	modalBody: {
		padding: "0"
	},
	modalContent: {
		flex: "0 0 auto"
	}

} ) );
